(function () {
  'use strict';
   //the rest of the function
}());


const am = angular.module('ep-dealer-revenue-share-downtime', ['ui.router', 'powerbi']);

am.controller('dealerRevenueShareDtTileCtrl', ['$scope', 'loginSvc', (function($scope, loginSvc) {
    $scope.languagePreference = sessionStorage.languagePreference;

    loginSvc.getJSONFileData().then(({ data }) => {

      if (sessionStorage.languagePreference.toLowerCase() === 'en') {
        // Revenue share tile image EN
        $scope.logoAsset =  data.osrscaRevShareTileImage.enUrl;
      }
      else if (sessionStorage.languagePreference.toLowerCase() === 'fr') {
        // Revenue share tile image FR
        $scope.logoAsset =  data.osrscaRevShareTileImage.frUrl;
      }
         
    }).catch((err) => {
      console.log('Oops, error getting getJSONFileData' + err);
    });
})]);